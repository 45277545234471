<template>
  <b-modal
    :id="id"
    ok-only
    ok-title="Accept"
    centered
    class="modal-emptask"
    content-class="emp-approval-detail"
  >
    <template #modal-title>
      <div
        v-b-toggle.collapse-1
        class="d-flex align-items-center"
      >
        <b-avatar
          text=""
          size="50"
          style="background-color: #f97753"
        >{{
          taskdetail.project.name.charAt(0)
        }}</b-avatar>
        <div class="emp-project-modal">
          <p class="emp-modal-project">
            {{ taskdetail.project.name }}
          </p>
        </div>
      </div>
    </template>
    <b-card-text>
      <div class="modal-horizontalline" />
      <p class="emp-modal-task-title">
        {{ taskdetail.title }}
      </p>
      <span
        v-for="(tag, index) in taskdetail.user_task_tags"
        :key="index"
        class="emp-modal-task-sprint"
      >{{ tag.tag }}</span>
      <table>
        <tr>
          <th class="pl-0">
            Duration
          </th>
          <th>created</th>
        </tr>
        <tr>
          <td class="pl-0">
            {{ taskdetail.count_minutes }}
          </td>
          <td>{{ taskdetail.date | formatDateDDMMYYYY }}</td>
        </tr>
      </table>
    </b-card-text>
    <template #modal-footer>
      <b-button class="d-none" />
      <b-button class="d-none" />
    </template>
  </b-modal>
</template>

<script>
import { BModal, VBModal, BCardText } from 'bootstrap-vue'
import { eventBus } from '../../main'

export default {
  name: 'EmpTaskDetail',
  components: {
    BModal,
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      taskdetail: [],
    }
  },
  mounted() {
    eventBus.$on('taskdetail', data => {
      if (data) {
        this.taskdetail = data
      }
    })
  },
  destroyed() {
    eventBus.$off('taskdetail')
  },
}
</script>

<style>
</style>
