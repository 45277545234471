<template>
  <div class="task-detail">
    <div>
      <p class="task-table-daterange mb-1">
        {{
          taskList &&
            taskList.length &&
            taskList[0].project &&
            taskList[0].project.name
            ? taskList[0].project.name
            : null
        }}
        <span
          style="color: #D77418;
        margin-left: 5px;"
        >{{ totalTime |convertMinutes }}</span>
      </p>
      <div class="table role ">
        <b-row>
          <b-col cols="12">
            <!-- data table start-->
            <b-table
              responsive
              :items="taskList"
              :fields="fields"
              show-empty
              class="scrollable-element tableDesigns update-emp-table mastertblColor non-project-table"
            >
              <template #cell(task)="data">
                <div class="w-full demo">
                  <div class="task">
                    <div>
                      <p class="task-name">
                        {{ data.item.title }}
                        <b-badge
                          v-if="data.item.is_manual"
                          class="manualTag"
                        >
                          Manual
                        </b-badge>
                      </p>

                      <div class="d-flex flex-wrap">

                        <b-badge
                          v-for="(tag, tindex) in data.item.user_task_tags"
                          :key="tindex"
                          variant="primary"
                          class="timesheet-body-badge smallBadge"
                        >
                          {{ tag.tag }}
                        </b-badge>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(time)="data">
                <p
                  class="tableData"
                  style="text-transform: none;"
                >{{ data.item.start_time | timeFormat }} -
                  {{ data.item.end_time | timeFormat }}</p>
              </template>
              <template #cell(date)="data">
                <p class="tableData">
                  {{ data.item.date | formatDateDDMMYYYY }}
                </p>
              </template>
              <template #cell(duration)="data">
                <div class="d-flex align-itmes-center">
                  <p class="ml-50 tableData">
                    {{ (data.item.total_time / 60)| hoursInTimeFormatForSummary }}
                  </p>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BTable,
} from 'bootstrap-vue'

export default {
  name: 'UpdateEmpList',
  components: {
    BRow,
    BCol,
    BTable,
  },
  props: {
    taskList: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      fields: [
        { key: 'task', label: 'Task', thStyle: { minWidth: '700px', textTransform: 'uppercase' } },
        {
          key: 'time',
          label: 'Time',
          thStyle: { minWidth: '350px', textAlign: 'start', textTransform: 'uppercase' },
        },
        {
          key: 'date',
          label: 'Date',
          thStyle: { width: '300px', textAlign: 'start', textTransform: 'uppercase' },
        },
        {
          key: 'duration',
          label: 'Duration',
          thStyle: { minWidth: '200px', textAlign: 'start', textTransform: 'uppercase' },
        },
      ],

    }
  },
  computed: {
    totalTime() {
      let total = 0
      this.taskList.forEach(element => {
        total += element && element.total_time ? element.total_time : 0
      })
      return total
    },
  },

}
</script>
<style lang="scss" scoped>
@import "../../assets/scss/component-css/viewbody.css";
@import "../../assets/scss/variables/variables";
.timesheet-body-badge {
  margin: 5px 8px 0px 0;
  font-size: 1.5 * $rem;
  font-weight: $fw500;
  line-height: 22px;
  padding: 4px 10px 4px 10px;
  background-color: $light-red;
  opacity: 0.72;
  border-radius: 5px;
  text-align: center;
  text-transform: capitalize;
  &.smallBadge {
    line-height: 12px;
    padding: 5px;
  }
  @media (max-width: map-get($mediaSizes , "lg")) {
    font-size: 10px;
    line-height: 17px;
  }
}

.manualTag {
  background-color: rgba(35, 184, 114, 0.12);
  border-radius: 20px;
  padding: 8px 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #23b872;
  cursor: default !important;
}
.task-table-daterange {
  color: $primary-color;
  font-weight: $fw700;
  font-size: 2.25 * $rem;
}
.project-name {
  color: $secondary-color;
  font-size: 1.75 * $rem;
  font-weight: $fw500;
  line-height: 24px;
  text-transform: capitalize;
  margin-right: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  @media (max-width: map-get($mediaSizes , "lg")) {
    font-size: 1.75 * $rem;
  }
}
</style>
