<template>
  <div class="weekly-mainsection">
    <div class="weekly-timesheet-calendar">
      <table>
        <tr>
          <th class="table-title">
            Weekly Timesheet
          </th>
          <th
            v-for="(date, dateKey) in weekDates"
            :key="dateKey"
          >
            <div class="table-head">
              <span>{{ date | getDayOnly }}</span>
              <div>
                <p>{{ date | getDayNameOnly }}</p>
                <p class="month">
                  {{ date | getMonthOnly }}
                </p>
              </div>
            </div>
          </th>
          <th>Total</th>
        </tr>
        <tr class="allproject-total">
          <td>Clock Timesheet</td>
          <td
            v-for="(cio, index) in clockInOutTime"
            :key="index"
          >
            <span v-if="cio.total_time">
              {{ cio.total_time }}
            </span>
            <span v-else>-</span>

          </td>
          <td>
            <p>
              {{ totalWeeklyClockInOutTime | secondsToTime }}
            </p>
          </td>
        </tr>
        <tr class="allproject-total">
          <td>Task Timesheet</td>

          <td
            v-for="(cio, index) in taskWeekDates"
            :key="index"
          >
            <span v-if="cio.total_time">
              {{ cio.total_time | secondsToTime }}
            </span>
            <span v-else>-</span>
          </td>
          <td>
            <p>
              {{ totalWeeklyTaskTime | secondsToTime }}
            </p>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Weekly',
  props: {
    startDate: {
      type: String,
      required: false,
      default: null,
    },
    endDate: {
      type: String,
      required: false,
      default: null,
    },
    userId: {
      type: String,
      required: true,
      default: null,
    },
    totalTasks: {
      type: Array,
      required: true,
      default: () => ([]),
    },
  },
  data() {
    return {
      loader: true,
      weekDates: [],
      weekData: [],
      clockInOutTime: [],
      taskWeekDates: [],
      totalWeeklyTaskTime: 0,
    }
  },

  computed: {
    totalWeeklyClockInOutTime() {
      let total = 0
      this.clockInOutTime.forEach(element => {
        const time = element.total_time
          ? element.total_time.split(':')
          : '00:00'.split(':')
        const hour = parseInt(time[0]) * 60
        const min = parseInt(time[1]) + hour
        total += min
      })

      return total * 60
    },
  },
  watch: {
    totalTasks() {
      this.taskArrayMake()
    },
  },
  mounted() {
    this.weekDates = this.enumerateDaysBetweenDates(this.startDate, this.endDate)
    this.getClockInOutTime(this.startDate, this.endDate)
    this.taskArrayMake()
  },

  methods: {
    /*
     * get user weekly clock in out time
     */
    async getClockInOutTime() {
      const input = {
        start_date: this.startDate,
        end_date: this.endDate,
        user_id: this.userId,
      }
      const response = await this.getHTTPPostResponse(
        'clock-in-out/total-clock-in-out',
        input,
      )
      if (response && response.status == 200) {
        this.clockInOutTime = response.data
      }
    },

    taskArrayMake() {
      const taskWeekDates = []
      let total = 0
      if (this.totalTasks && this.totalTasks.length) {
        this.weekDates.forEach(element => {
          let total_time = 0
          const tasks = this.totalTasks.filter(task => task.date == element)
          tasks.forEach(e => {
            total_time += e.total_time
            total += e.total_time
          })

          taskWeekDates.push({
            date: element,
            total_time: total_time * 60,
          })
        })
      }
      this.taskWeekDates = taskWeekDates
      this.totalWeeklyTaskTime = total * 60
    },
  },
}
</script>

<style>
@import "../../assets/scss/component-css/viewbody.css";
.text-total-time {
  color: #2178fb;
}
.text-lowtotal-time {
  color: #fb5362;
}
.submitButtonCursor {
  cursor: not-allowed;
}
.w-180 {
  width: 150px;
}
</style>
